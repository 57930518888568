import * as React from "react";
import * as styles from "./footer.module.scss";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <br />
    </div>
  );
}
